import { createSlice } from "@reduxjs/toolkit";

export interface IAuthState {
  user: Record<string, any>;
  isAuthenticated: boolean;
  menuCollapse: boolean;
}

const initialState: IAuthState = {
  user: {},
  isAuthenticated: false,
  menuCollapse: true,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
      isAuthenticated: true,
    }),
    logout: (state) => ({
      ...state,
      user: {},
      isAuthenticated: false,
      // menuCollapse: true,
    }),
    setMenuCollapse: (state, action) => ({
      ...state,
      menuCollapse: action.payload,
    }),
  },
});

export const { login, logout, setMenuCollapse } = AuthSlice.actions;
export default AuthSlice;
