import { createSlice } from "@reduxjs/toolkit";
import {
  MODEL_CONFIGURATION_STEP_ID,
  MODEL_CONFIG_TABS,
  MODEL_MASTER_DATA_TABS,
} from "../../utils/status";

export interface IMasterState {
  vehicleDetailsTabs: Record<string, any>[];
  bmsDetailsTabs: Record<string, any>[];
  mcuDetailsTabs: Record<string, any>[];
  modelConfigTabs: Record<string, any>[];
  modelMasterDataTabs: Record<string, any>;
  vehicle_uid: string;
  ticket_state_filter: any;
  fault_data: any;
  bms_statistics_data: any;
  bms_soc_data: any;
  device_status_filter: any;
  onboard_filter: any;
  model_page_component: string;
  vehicle_Over_All_Info: Record<string, any> | undefined;
  bms_Over_All_Info: Record<string, any> | undefined;
  mcu_Over_All_Info: Record<string, any> | undefined;
  geo_fence_data: Record<string, any> | undefined;
  device_ota_release_data: Record<string, any> | undefined;
  ota_device_filter_data: Record<string, any> | undefined;
  bms_reset_device_uid: any;
  userManagementTab: Record<string, any>[];
}

const initialState: IMasterState = {
  vehicleDetailsTabs: [
    {
      name: "Overview",
      id: "vehicle_overview",
      href: "#",
      current: true,
      // component: <VehicleOverview />,
    },
    {
      name: "Trip Analysis",
      id: "vehicle_trp_history",
      href: "#",
      current: false,
      // component: <TripHistory />,
    },
    {
      name: "Notifications",
      id: "vehicle_notifications",
      href: "#",
      current: false,
      // component: <NotificationHistory />,
    },
    {
      name: "Commands",
      id: "vehicle_command",
      href: "#",
      current: false,
    },
    {
      name: "Fault & Alerts",
      id: "vehicle_faults",
      href: "#",
      current: false,
      // component: <FaultManagement />,
    },
    {
      name: "Analysis",
      id: "vehicle_analysis",
      href: "#",
      current: false,
      // component: <HistoryData />,
    },
    {
      name: "Attributes",
      id: "vehicle_attributes",
      href: "#",
      current: false,
      // component: <HistoryData />,
    },
    {
      name: "Location Analysis",
      id: "vehicle_location",
      href: "#",
      current: false,
    },
  ],
  bmsDetailsTabs: [
    {
      name: "Overview",
      id: "bms_overview",
      href: "#",
      current: true,
      // component: <BMSOverview />,
    },
    {
      name: "Battery State Cycle",
      id: "bms_charging_cycle",
      href: "#",
      current: false,
    },
    {
      name: "Notifications",
      id: "bms_notifications",
      href: "#",
      current: false,
      // component: <NotificationHistory />,
    },
    {
      name: "Commands",
      id: "bms_command",
      href: "#",
      current: false,
    },
    {
      name: "Fault & Alerts",
      id: "bms_faults",
      href: "#",
      current: false,
      // component: <FaultManagement />,
    },
    {
      name: "Analysis",
      id: "bms_analysis",
      href: "#",
      current: false,
      // component: <HistoryData />,
    },
    {
      name: "Attributes",
      id: "bms_attributes",
      href: "#",
      current: false,
      // component: <HistoryData />,
    },
    {
      name: "Location Analysis",
      id: "bms_location",
      href: "#",
      current: false,
    },
  ],
  mcuDetailsTabs: [
    {
      name: "Overview",
      id: "mcu_overview",
      href: "#",
      current: true,
      // component: <MCUOverview />,
    },
    {
      name: "Notifications",
      id: "mcu_notifications",
      href: "#",
      current: false,
      // component: <NotificationHistory />,
    },
    {
      name: "Commands",
      id: "mcu_command",
      href: "#",
      current: false,
    },
    {
      name: "Fault & Alerts",
      id: "mcu_faults",
      href: "#",
      current: false,
      // component: <FaultManagement />,
    },
    {
      name: "Analysis",
      id: "mcu_analysis",
      href: "#",
      current: false,
      // component: <HistoryData />,
    },
    {
      name: "Location Analysis",
      id: "mcu_location",
      href: "#",
      current: false,
    },
    // {
    //   name: "Attributes",
    //   id: "mcu_attributes",
    //   href: "#",
    //   current: false,
    //   // component: <HistoryData />,
    // },
  ],
  modelConfigTabs: [
    {
      name: "Information",
      id: MODEL_CONFIG_TABS.INFORMATION,
      step: 1,
      step_id: MODEL_CONFIGURATION_STEP_ID.INFORMATION,
      current: true,
    },
    {
      name: "Master Data",
      id: MODEL_CONFIG_TABS.MASTER_DATA,
      step: 2,
      step_id: null,
      current: false,
    },
    {
      name: "Commands",
      id: MODEL_CONFIG_TABS.COMMANDS,
      step: 3,
      step_id: MODEL_CONFIGURATION_STEP_ID.COMMANDS,
      current: false,
    },
    {
      name: "Faults",
      id: MODEL_CONFIG_TABS.FAULTS,
      step: 4,
      step_id: MODEL_CONFIGURATION_STEP_ID.FAULTS,
      current: false,
    },
    {
      name: "Configurations",
      id: MODEL_CONFIG_TABS.CONFIGURATION,
      step: 5,
      step_id: MODEL_CONFIGURATION_STEP_ID.CONFIGURATION,
      current: false,
    },
    {
      name: "Telemetry",
      id: MODEL_CONFIG_TABS.TELEMETRY,
      step: 6,
      step_id: MODEL_CONFIGURATION_STEP_ID.TELEMETRY,
      current: false,
    },
  ],
  modelMasterDataTabs: {
    VCU: [
      {
        name: "Vehicle State",
        id: MODEL_MASTER_DATA_TABS.VCU_VEHICLE_STATE,
        step: 1,
        step_id: MODEL_CONFIGURATION_STEP_ID.VCU_VEHICLE_STATE,
        current: true,
      },
      // {
      //   name: "Drive Mode",
      //   id: MODEL_MASTER_DATA_TABS.VCU_DRIVE_MODE,
      //   step: 3,
      //   step_id: MODEL_CONFIGURATION_STEP_ID.VCU_DRIVE_MODE,
      //   current: false,
      // },
    ],
    BMS: [
      {
        name: "BMS State",
        id: MODEL_MASTER_DATA_TABS.BMS_STATE,
        step: 1,
        step_id: MODEL_CONFIGURATION_STEP_ID.BMS_STATE,
        current: true,
      },
    ],
    MCU: [
      {
        name: "MCU State",
        id: MODEL_MASTER_DATA_TABS.MCU_STATE,
        step: 1,
        step_id: MODEL_CONFIGURATION_STEP_ID.MCU_STATE,
        current: true,
      },
    ],
  },
  vehicle_uid: "",
  ticket_state_filter: undefined,
  fault_data: undefined,
  bms_statistics_data: undefined,
  bms_soc_data: undefined,
  device_status_filter: undefined,
  onboard_filter: undefined,
  model_page_component: "",
  vehicle_Over_All_Info: undefined,
  bms_Over_All_Info: undefined,
  mcu_Over_All_Info: undefined,
  geo_fence_data: undefined,
  device_ota_release_data: undefined,
  ota_device_filter_data: undefined,
  bms_reset_device_uid: undefined,
  userManagementTab: [
    {
      name: "Users",
      id: "users",
      href: "#",
      current: true,
    },
    {
      name: "Role & Permissions",
      id: "role",
      href: "#",
      current: false,
    },
    {
      name: "Device Group",
      id: "group",
      href: "#",
      current: false,
    },
  ],
};

const DetailsTabsSlice = createSlice({
  name: "detailsTab",
  initialState,
  reducers: {
    setDetailsTabInitialState: () => initialState,
    setVehicleDetailsActiveTab: (state, action) => ({
      ...state,
      vehicleDetailsTabs: action.payload,
    }),
    setBMSDetailsActiveTab: (state, action) => ({
      ...state,
      bmsDetailsTabs: action.payload,
    }),
    setMCUDetailsActiveTab: (state, action) => ({
      ...state,
      mcuDetailsTabs: action.payload,
    }),
    setVehicleUID: (state, action) => ({
      ...state,
      vehicle_uid: action.payload,
    }),
    setTicketStateFilter: (state, action) => ({
      ...state,
      ticket_state_filter: action.payload,
      fault_data: undefined,
      bms_statistics_data: undefined,
      bms_soc_data: undefined,
      device_status_filter: undefined,
      onboard_filter: undefined,
    }),
    setSelectedFaultData: (state, action) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: action.payload,
      bms_statistics_data: undefined,
      bms_soc_data: undefined,
      device_status_filter: undefined,
      onboard_filter: undefined,
    }),
    setDeviceStatusFilter: (state, action) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: undefined,
      bms_statistics_data: undefined,
      bms_soc_data: undefined,
      device_status_filter: action.payload,
      onboard_filter: undefined,
    }),
    setDeviceOnboardFilter: (state, action) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: undefined,
      bms_statistics_data: undefined,
      bms_soc_data: undefined,
      device_status_filter: undefined,
      onboard_filter: action.payload,
    }),
    setBMSStatisticsFilter: (state, action) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: undefined,
      bms_statistics_data: action.payload,
      bms_soc_data: undefined,
      device_status_filter: undefined,
      onboard_filter: undefined,
    }),
    setBMSSocFilter: (state, action) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: undefined,
      bms_statistics_data: undefined,
      bms_soc_data: action.payload,
      device_status_filter: undefined,
      onboard_filter: undefined,
    }),
    setClearAllFilter: (state) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: undefined,
      bms_statistics_data: undefined,
      bms_soc_data: undefined,
      device_status_filter: undefined,
      onboard_filter: undefined,
    }),
    setModelConfigActiveTab: (state, action) => ({
      ...state,
      modelConfigTabs: state.modelConfigTabs.map((tab) => ({
        ...tab,
        current: tab.id === action.payload,
      })),
    }),
    setCurrentModelPage: (state, action) => ({
      ...state,
      model_page_component: action.payload,
    }),
    setCurrentModelMasterDataPage: (state, action) => ({
      ...state,
      modelMasterDataTabs: {
        ...state.modelMasterDataTabs,
        [action.payload.component]: state.modelMasterDataTabs[action.payload.component].map(
          (tab: any) => ({
            ...tab,
            current: tab.id === action.payload.id,
          }),
        ),
      },
    }),
    setVehicleOverAllInfo: (state, action) => ({
      ...state,
      vehicle_Over_All_Info: action.payload,
      bms_Over_All_Info: undefined,
      mcu_Over_All_Info: undefined,
    }),
    setBMSOverAllInfo: (state, action) => ({
      ...state,
      vehicle_Over_All_Info: undefined,
      bms_Over_All_Info: action.payload,
      mcu_Over_All_Info: undefined,
    }),
    setMCUOverAllInfo: (state, action) => ({
      ...state,
      vehicle_Over_All_Info: undefined,
      bms_Over_All_Info: undefined,
      mcu_Over_All_Info: action.payload,
    }),
    setGeofenceData: (state, action) => ({
      ...state,
      geo_fence_data: action.payload,
    }),
    setOTAReleaseData: (state, action) => ({
      ...state,
      device_ota_release_data: action.payload,
    }),
    setOTADeviceFilterData: (state, action) => ({
      ...state,
      ota_device_filter_data: action.payload,
    }),
    setBMSResetDeviceFilter: (state, action) => ({
      ...state,
      bms_reset_device_uid: action.payload,
    }),
    setUserManagementTab: (state, action) => ({
      ...state,
      userManagementTab: action.payload,
    }),
  },
});

export const {
  setDetailsTabInitialState,
  setVehicleDetailsActiveTab,
  setBMSDetailsActiveTab,
  setMCUDetailsActiveTab,
  setVehicleUID,
  setTicketStateFilter,
  setSelectedFaultData,
  setDeviceStatusFilter,
  setClearAllFilter,
  setDeviceOnboardFilter,
  setModelConfigActiveTab,
  setCurrentModelPage,
  setCurrentModelMasterDataPage,
  setVehicleOverAllInfo,
  setBMSOverAllInfo,
  setMCUOverAllInfo,
  setGeofenceData,
  setOTAReleaseData,
  setOTADeviceFilterData,
  setBMSResetDeviceFilter,
  setBMSStatisticsFilter,
  setBMSSocFilter,
  setUserManagementTab,
} = DetailsTabsSlice.actions;
export default DetailsTabsSlice;
