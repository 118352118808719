import { createSlice } from "@reduxjs/toolkit";

export interface IMasterState {
  data: any;
  dataObj: {
    start_date?: Date;
    end_date?: Date;
  };
  vehicleUid: any;
  lastUpdated: any;
  isLive: any;
  commandAcknowledge: any;
  component_master_data: any;
  trip_data: any;
  fault_filter_on: any;
  trip_status: any;
  analysisdataObj: {
    start_date?: Date;
    end_date?: Date;
  };
  activeTripIndex: any;
  activeOffset: number;
  sleep_mode_data: any;
  loc_analysis_data: Record<string, any>[];
  model_telemetry_data: Record<string, any> | undefined;
  active_telemetry_att_key: string | undefined;
  telemetry_error_keys: string[];
}

const initialState: IMasterState = {
  data: {},
  dataObj: {},
  vehicleUid: undefined,
  lastUpdated: undefined,
  isLive: undefined,
  commandAcknowledge: undefined,
  component_master_data: undefined,
  trip_data: undefined,
  fault_filter_on: undefined,
  trip_status: undefined,
  analysisdataObj: {},
  activeTripIndex: null,
  activeOffset: 1,
  sleep_mode_data: undefined,
  loc_analysis_data: [],
  model_telemetry_data: undefined,
  active_telemetry_att_key: undefined,
  telemetry_error_keys: [],
};

const VehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    setVehicleData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    setDateRange: (state, action) => ({
      ...state,
      dataObj: action.payload,
    }),
    setVehicleListSelect: (state, action) => ({
      ...state,
      vehicleUid: action.payload,
    }),
    setLastUpdated: (state, action) => ({
      ...state,
      lastUpdated: action.payload,
    }),
    setIsLive: (state, action) => ({
      ...state,
      isLive: action.payload,
    }),
    setCommandAcknowledge: (state, action) => ({
      ...state,
      commandAcknowledge: action.payload,
    }),
    setComponentMasterData: (state, action) => ({
      ...state,
      component_master_data: action.payload,
    }),
    setTripdata: (state, action) => ({
      ...state,
      trip_data: action.payload,
    }),
    setFaultFilter: (state, action) => ({
      ...state,
      fault_filter_on: action.payload,
    }),
    setTripSTatus: (state, action) => ({
      ...state,
      trip_status: action.payload,
    }),
    setAnalysisDateObj: (state, action) => ({
      ...state,
      analysisdataObj: action.payload,
    }),
    setActiveTripIndex: (state, action) => ({
      ...state,
      activeTripIndex: action.payload,
    }),
    setActiveOffset: (state, action) => ({ ...state, activeOffset: action.payload }),
    sleetModeData: (state, action) => ({
      ...state,
      sleep_mode_data: action.payload,
    }),
    setLocAnalysisData: (state, action) => ({
      ...state,
      loc_analysis_data: action.payload,
    }),
    setModelTelemetryData: (state, action) => ({
      ...state,
      model_telemetry_data: action.payload,
    }),
    setActiveTelemetryAttKey: (state, action) => ({
      ...state,
      active_telemetry_att_key: action.payload,
    }),
    setTelemetryErrorKeyArr: (state, action) => ({
      ...state,
      telemetry_error_keys: action.payload,
    }),
  },
});

export const {
  setVehicleData,
  setTripSTatus,
  setDateRange,
  setVehicleListSelect,
  setLastUpdated,
  setIsLive,
  setCommandAcknowledge,
  setComponentMasterData,
  setTripdata,
  setFaultFilter,
  setAnalysisDateObj,
  setActiveTripIndex,
  setActiveOffset,
  sleetModeData,
  setLocAnalysisData,
  setModelTelemetryData,
  setActiveTelemetryAttKey,
  setTelemetryErrorKeyArr,
} = VehicleSlice.actions;
export default VehicleSlice;
