import React from "react";
import classes from "./Footer.module.scss";

const Footer = (): JSX.Element => (
  <div className={`${classes.container}`}>
    <div>
      &copy; {new Date().getFullYear()} IntuEnergy Technologies Pvt. Ltd. All Rights Reserved.
    </div>
    {/* <div>
      <a target="_blank" href={`https://intuions.com/privacy-policy/`} rel="noreferrer">
        Privacy Policy
      </a>
    </div> */}
  </div>
);

export default Footer;
