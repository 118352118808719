import Footer from "../Footer/Footer";

const ErrorPage = () => (
  <div className="w-full h-screen flex flex-col gap-5 justify-center items-center text-font-textcolor4">
    <h1 className="text-h6 w-[600px] font-bold text-center text-[#212B36] pb-5">
      <span className="text-[#22C55E]">Oops!</span> Something went wrong.
    </h1>
    <p className="text-mediumtext w-[800px] text-center">
      We&apos;re sorry, but it seems like we&apos;ve encountered an unexpected error.
    </p>
    <p className="text-mediumtext w-[600px] text-center">
      Please refresh the page or come back later.
    </p>
    <img src="/images/error_illustration.png" alt="error" className="w-[450px] pt-5" />
    <Footer />
  </div>
);

export default ErrorPage;
