import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import Flatted from "flatted";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import AuthSlice from "./Slices/AuthSlice";
import MasterSlice from "./Slices/MasterSlice";
import VehicleSlice from "./Slices/VehicleSlice";
import SocketSlice from "./Slices/SocketSlice";
import DetailsTabsSlice from "./Slices/DetailsTabsSlice";
import ModelSlice from "./Slices/ModelSlice";

export const transformCircular = createTransform(
  (inboundState) => Flatted?.stringify(inboundState),
  (outboundState) => Flatted?.parse(outboundState),
  { whitelist: [SocketSlice.name] },
);

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: [
    AuthSlice.name,
    MasterSlice.name,
    SocketSlice.name,
    DetailsTabsSlice.name,
    ModelSlice.name,
  ],
  transforms: [transformCircular],
};

const reducers = combineReducers({
  [AuthSlice.name]: AuthSlice.reducer,
  [MasterSlice.name]: MasterSlice.reducer,
  [VehicleSlice.name]: VehicleSlice.reducer,
  [SocketSlice.name]: SocketSlice.reducer,
  [DetailsTabsSlice.name]: DetailsTabsSlice.reducer,
  [ModelSlice.name]: ModelSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
