import { createSlice } from "@reduxjs/toolkit";

export interface IMasterState {
  model_data: any;
}

const initialState: IMasterState = {
  model_data: undefined,
};

const ModelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    setModelConfigData: (state, action) => ({
      ...state,
      model_data: action.payload,
    }),
  },
});

export const { setModelConfigData } = ModelSlice.actions;

export default ModelSlice;
